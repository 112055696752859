import React, { useEffect, useState } from 'react';

export default () => {
  const [tagId, setTagId] = useState(null);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);

    setTagId(params.get('tagId') || null);
  }, []);

  if (!tagId) {
    return null;
  }
  return (
    <div class="border-solid border border-green-brand rounded-md p-2 bg-green-brand/20">
      <p class="m-0 !mb-0 text-black">Référence variable clientId : {tagId}</p>
    </div>
  );
};
